// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/estaid-logo.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_Xq4op{align-items:center;height:100vh;justify-content:center;outline:solid 1px var(--color-black-200);width:100vw}.container_Xq4op,.inner_FUDZI{display:flex;flex-direction:column}.inner_FUDZI{width:22rem}.buttons_ICzUp{display:flex}.buttonInner_q9hk5{display:flex;flex-direction:column;width:12rem}.logo_36IqH{background-color:var(--color-black-900);display:inline-flex;flex-grow:0;flex-shrink:0;height:8rem;margin:0 auto;-webkit-mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");-webkit-mask-position:center center;mask-position:center center;-webkit-mask-repeat:no-repeat;mask-repeat:no-repeat;-webkit-mask-size:contain;mask-size:contain;width:10rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_Xq4op",
	"inner": "inner_FUDZI",
	"buttons": "buttons_ICzUp",
	"buttonInner": "buttonInner_q9hk5",
	"logo": "logo_36IqH"
};
module.exports = ___CSS_LOADER_EXPORT___;
